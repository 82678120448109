import React, { useEffect, useState } from "react";
import "./Header.scss";
import { postRequest } from "../../../services/api/axios_api_Request";
import pacelogo from "../../../assets/images/profile-image.png"

const Header = () => {
  return (
    <header className="header">
      {/* Logo Section */}
      <div className="header__logo">
        <a href="https://pharmaace.com">
          <img
            src={pacelogo}
            alt="PharmaACE"
            className="header__logo-image"
          />
        </a>
      </div>

      {/* Contact Button Section */}
      <div className="header__contact">
        <a
          href="https://pharmaace.com/contact-us/"
          className="header__contact-button"
        >
          Contact Us
        </a>
      </div>
    </header>
  );
};

export default Header;
