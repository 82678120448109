
import "./App.css";

import React from 'react';
import FormComponent from "./app/module/FormComponent/FormComponent";

function App() {
  return (
    <div className="App">
      <FormComponent />
    </div>
  );
}

export default App;

