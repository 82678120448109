import React, { useEffect, useState } from "react";
import "./FormComponent.scss";
import Header from "../../components/headerbar/Header";
import {
  TextField,
  Button,
  Box,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { postRequest } from "../../../services/api/axios_api_Request";
 
const FormComponent = () => {
  const [isFormAlreadySubmmited, setIsFormAlreadySubmitted] = useState();
 
  const [formData, setFormData] = useState({
    id: "",
    username: "",
    password: "",
    dob: "",
    mobile_no: "",
    employee_id: "",
    adhar_card: "",
    emergency_contact: "",
  });
 
  const [showPassword, setShowPassword] = useState(false);
 
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
 
  const submitForm = async (event) => {
    event.preventDefault();
    try {
      // console.log(formData);
      const res = await postRequest("/save_employee_info", formData); // Send `formData` directly as JSON
      // console.log(res, "submit");
      if (res?.status === 201) {
        setIsFormAlreadySubmitted(true);
      }
    } catch (error) {
      // console.log(error);
    }
  };
 
  const handleChange = (event) => {
    const { name, value } = event.target;
    // console.log(name, value, "chin");
    setFormData((prevVal) => {
      return {
        ...prevVal,
        [name]: value,
      };
    });
  };
 
  return (
    <>
      <Header></Header>
      {isFormAlreadySubmmited ? (
        <>
          <Box className="form-container-2">
            <span>Thanks, Your response has been recorded.</span>
          </Box>
        </>
      ) : (
        <Box className="form-container">
          <Typography variant="h5" component="h2" className="form-title">
            Security Protocol Verification
          </Typography>
          <Typography variant="body2" className="form-instructions">
            As part of PharmaACE’s commitment to robust security standards,
            please verify your information below. This update should take only a
            few minutes.
          </Typography>
          <form onSubmit={submitForm}>
            <TextField
              label="Pharmaace Email ID"
              variant="outlined"
              fullWidth
              name="username"
              required
              onChange={(event) => handleChange(event)}
              className="form-input"
              helperText="Please enter your username to verify your account status"
              margin="normal"
            />
 
            <TextField
              label="Last 4 Characters of Password"
              variant="outlined"
              required
              name="password"
              fullWidth
              className="form-input"
              helperText="For security confirmation, please enter the last 4 characters of your password"
              margin="normal"
              onChange={(event) => handleChange(event)}
              type={showPassword ? "text" : "password"}
              inputProps={{
                maxLength: 4,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
 
            <TextField
              label="Date of Birth"
              type="date"
              name="dob"
              variant="outlined"
              fullWidth
              onChange={(event) => handleChange(event)}
              className="form-input"
              helperText="For identity verification, please confirm your date of birth"
              InputLabelProps={{ shrink: true }}
              margin="normal"
            />
 
            <TextField
              label="Phone Number"
              variant="outlined"
              name="mobile_no"
              fullWidth
              className="form-input"
              margin="normal"
              onChange={(event) => handleChange(event)}
              inputProps={{
                maxLength: 10,
                inputMode: "numeric",
                pattern: "[0-9]*", // Ensures only numeric characters are entered
              }}
              type="text"
            />
 
            <TextField
              label="Last 4 Digits of Aadhar"
              variant="outlined"
              fullWidth
              name="adhar_card"
              onChange={(event) => handleChange(event)}
              className="form-input"
              helperText="To authenticate your account, enter the last 4 digits of your Aadhar"
              margin="normal"
              inputProps={{
                maxLength: 4,
                inputMode: "numeric",
                pattern: "[0-9]*", // Ensures only numeric characters are entered
              }}
              type="text"
            />
 
            <TextField
              label="Employee ID"
              variant="outlined"
              name="employee_id"
              required
              fullWidth
              onChange={(event) => handleChange(event)}
              className="form-input"
              helperText="Please enter your PharmaACE Employee ID"
              margin="normal"
            />
 
            <TextField
              label="Emergency Contact Number"
              variant="outlined"
              name="emergency_contact"
              fullWidth
              onChange={(event) => handleChange(event)}
              className="form-input"
              helperText="Please provide an emergency contact for account recovery purposes"
              margin="normal"
              inputProps={{
                maxLength: 10,
                inputMode: "numeric",
                pattern: "[0-9]*", // Ensures only numeric characters are entered
              }}
            />
 
            <Button
              type="submit"
              // onClick={submitForm}
              variant="contained"
              color="primary"
              className="form-submit"
            >
              Submit
            </Button>
          </form>
        </Box>
      )}
    </>
  );
};
 
export default FormComponent;